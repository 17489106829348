/* ImageViewer 既存の上書き */
.viewer-footer {
  bottom: 25px;
}

.viewer-toolbar > ul > .viewer-large {
  height: 55px;
  width: 55px;
}

.viewer-toolbar > ul > li + li {
  margin-left: 23px;
}

.viewer-zoom-in::before, .viewer-zoom-out::before, .viewer-one-to-one::before, .viewer-reset::before, .viewer-prev::before, .viewer-play::before, .viewer-next::before, .viewer-rotate-left::before, .viewer-rotate-right::before, .viewer-flip-horizontal::before, .viewer-flip-vertical::before, .viewer-fullscreen::before, .viewer-fullscreen-exit::before, .viewer-close::before {
  background-image: none;
  height: 50px;
  line-height: unset;
  width: 50px;
}

.viewer-zoom-in {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" /></svg>') no-repeat center center;
}

.viewer-zoom-out {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M20,14H4V10H20" /></svg>') no-repeat center center;
}

.viewer-reset {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M13,3A9,9 0 0,0 4,12H1L4.89,15.89L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3Z" /></svg>') no-repeat center center;
}

.viewer-rotate-left {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M13.5,7A6.5,6.5 0 0,1 20,13.5A6.5,6.5 0 0,1 13.5,20H10V18H13.5C16,18 18,16 18,13.5C18,11 16,9 13.5,9H7.83L10.91,12.09L9.5,13.5L4,8L9.5,2.5L10.92,3.91L7.83,7H13.5M6,18H8V20H6V18Z" /></svg>') no-repeat center center;
}

.viewer-rotate-right {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M10.5,7A6.5,6.5 0 0,0 4,13.5A6.5,6.5 0 0,0 10.5,20H14V18H10.5C8,18 6,16 6,13.5C6,11 8,9 10.5,9H16.17L13.09,12.09L14.5,13.5L20,8L14.5,2.5L13.08,3.91L16.17,7H10.5M18,18H16V20H18V18Z" /></svg>') no-repeat center center;
}

/* ImageViewer custom */
.viewer-custom-retake {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" /></svg>') no-repeat center center;
}

.viewer-custom-ok {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>') no-repeat center center;
}

.viewer-custom-delete {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>') no-repeat center center; left: 15px; position: absolute;
}

/* ImageViewer closeBtn */
.viewer-button {
  height: 50px;
  right: 15px;
  top: 15px;
  width: 50px;
}

.viewer-close {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="35px" height="35px" viewBox="0 0 24 24"><path fill="%23ffffff" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>') no-repeat center center;
  background-color: rgba(0, 0, 0, 0.5);
}
